import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
} from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { LocationService } from '@shared/services/location.service';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonSearchbar,
    IonList,
    IonItem,
    IonLabel,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Tag Location</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <form [formGroup]="formGroup">
          <ion-searchbar
            mode="ios"
            debounce="500"
            formControlName="Search"
            placeholder="Search locations"
            (ionChange)="search($event)"
            (ionClear)="clearSearch()"
          ></ion-searchbar>
        </form>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isLoading"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list lines="full">
        <ion-item
          [color]="location === item ? 'primary' : undefined"
          *ngFor="let item of list"
          (click)="select(item)"
        >
          <ion-label>
            {{ item }}
          </ion-label>
          <ion-button
            *ngIf="location === item"
            fill="clear"
            size="large"
            color="light"
            slot="end"
            (click)="clear()"
          >
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationModal implements OnInit {
  @Input() location: string;
  isLoading = false;
  searchTextControl = this.fb.nonNullable.control('');
  formGroup = this.fb.group({
    Search: ['', []],
  });
  list: string[] = [];
  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private locationService: LocationService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.getAddresses();
  }

  clearSearch() {
    this.setList([]);
    this.cdr.markForCheck();
  }

  setList(listFromService: string[]) {
    if (
      this.location &&
      !listFromService.some((item) => item === this.location)
    ) {
      this.list = [this.location, ...listFromService];
    } else {
      this.list = listFromService;
    }
  }

  async search(e: SearchbarCustomEvent) {
    const value = e.detail.value.trim();

    if (!value) {
      this.clearSearch();
      return;
    }

    this.isLoading = true;
    this.cdr.markForCheck();
    try {
      const list = await this.locationService.getAddressesFromLocation(value);
      this.setList(list);
    } catch (error) {
      console.error('could not determine location', error);
    }
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  select(address: string) {
    this.modalService.dismiss(address, 'success');
  }

  async getAddresses() {
    this.isLoading = true;
    this.cdr.markForCheck();
    try {
      const list = await this.locationService.getLocationAddresses();
      this.setList(list);
    } catch (error) {
      this.toastService.show('Could not determine your location');
      console.error('could not determine location');
    }
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  clear() {
    this.modalService.dismiss('', 'clear');
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
