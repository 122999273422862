import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IState, IUserDataSummary, IUserSettings } from '@models';
import { select, Store } from '@ngrx/store';
import * as userSelectors from '@store/selectors/user';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { LocationService } from '@shared/services/location.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private store: Store<IState>,
    private storage: LocalStorageService,
    private locationService: LocationService
  ) {}

  getDataSummary() {
    return this.http.get<IUserDataSummary>('datasummary/user');
  }

  getSettings() {
    return this.http.get<IUserSettings>('social/settings');
  }

  updateSettings(settings: Partial<IUserSettings>) {
    return this.http.put<IUserSettings>('social/settings', settings);
  }

  deleteUser() {
    return this.http.delete<void>('account?confirm=true');
  }

  logout() {
    return this.http.post<void>('account/logout', {});
  }

  agreeToTerms() {
    return this.http.post<void>('agreetoterms', {});
  }

  async getLocationIfAllowed() {
    const shareLocation = await firstValueFrom(
      this.store.pipe(select(userSelectors.selectShareLocation))
    );
    const location = await this.storage.get(LocalStorageService.locationKey);
    if (shareLocation) {
      if (location) {
        return location;
      }
      try {
        return await this.locationService.getLocationAddress2();
      } catch (error) {
        return '';
      }
    } else {
      return '';
    }
  }

  async getLocation() {
    const location = await this.storage.get(LocalStorageService.locationKey);
    if (location) {
      return location;
    }
    try {
      return await this.locationService.getLocationAddress2();
    } catch (error) {
      return '';
    }
  }
}
