import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionText',
  standalone: true,
})
export class actionTextSocialPipe implements PipeTransform {
  transform(value: 'Scan' | 'Review' | 'Share' | string): any {
    switch (value) {
      case 'Scan':
        return 'scanned';
      case 'Review':
        return 'reviewed';
      case 'Share':
        return 'shared';
      default:
        return value;
    }
  }
}
