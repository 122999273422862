import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CigarLogInfoService {


  constructor(
    private http: HttpClient
  ) { }

  getImageUrl(blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob);

    return this.http.post<string>('images/usercigarimage', formData);
  }
}
