import { Component } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular/standalone';
import { ReportReasonModel } from '@shared/models/report-reason.model';

@Component({
    templateUrl: 'report-popover.html',
})
export class ReportPopover {
    reportReasons: ReportReasonModel[];

    constructor(
        private popoverCtrl: PopoverController,
        public params: NavParams
    ) { }

    ngOnInit() {
        this.reportReasons = this.params.get('reasons');
    }

    close() {
        this.popoverCtrl.dismiss();
    }

    report(type) {
        this.params.get('showConfirm')(type);
        this.popoverCtrl.dismiss();
    }
}
