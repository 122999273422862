import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'replaceHashesWithSpans',
  standalone: true,
})
export class ReplaceHashesWithSpansPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(string) {
    try {
      return this.replaceHashTagsWithSpanElements(string);
    } catch (error) {
      console.error(error);
      return string;
    }
  }

  replaceHashTagsWithSpanElements(string) {
    string = string.replace(/##(.*?)##/g, (val) => {
      const [id, name] = val.substring(2).slice(0, -2).split(':');
      if (!id || !name) return val;
      return `<span userid="${id}">${name}</span>`;
    });
    string = string.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }
}
