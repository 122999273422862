import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonButton,
  IonIcon,
  PopoverController,
} from '@ionic/angular/standalone';
import { IUserShort } from '@models/user';
import { IComment } from '@shared/models/comment-model';
import { getUserTypeAndId } from '@utils/users';
import { addIcons } from 'ionicons';
import { ellipsisVertical } from 'ionicons/icons';

import {
  CommentActionsPopover,
  CommonMenuAction,
} from './comments-actions.popover';
import { CommonModule } from '@angular/common';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { AgoPipe } from '@shared/pipes/ago.pipe';
import { ReplaceHashesWithSpansPipe } from '@shared/pipes/replace-hashes-with-spans.pipe';

@Component({
  selector: 'social-post-comment',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonIcon,
    CreateSourceStandalonePipe,
    UserNamePipe,
    ReplaceHashesWithSpansPipe,
    AgoPipe,
  ],
  template: `
    <li
      class="px-3 pt-3"
      [ngClass]="{ 'bg-indigo-200 dark:bg-indigo-600': isHighlighted }"
    >
      <div class="relative pb-3">
        <span
          *ngIf="hasMore"
          class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600"
          aria-hidden="true"
        ></span>
        <div class="relative flex items-start space-x-3">
          <div class="relative">
            <img
              class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
              loading="lazy"
              (click)="navigateToUserProfile(comment.User)"
              [src]="comment.User.AvatarUrl | createSource"
              src-fallback="assets/images/user-image.png"
            />
          </div>
          <div class="min-w-0 flex-1" (click)="commentClick.emit()">
            <div class="flex flex-row">
              <div class="flex-1">
                <div class="text-sm font-medium">
                  {{ comment.User | userName }}
                </div>
                <p
                  class="m-0 mt-0.5 text-xs text-gray-500 dark:text-gray-300 font-medium"
                >
                  {{ comment.CreatedOn | ago | async }}
                </p>
              </div>

              <ion-button
                *ngIf="showMenu"
                slot="end"
                color="dark"
                fill="clear"
                (click)="openActions($event)"
              >
                <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
              </ion-button>
            </div>
            <div class="mt-2 text-sm">
              <p
                class="!font-medium m-0"
                [innerHTML]="comment.Text | replaceHashesWithSpans"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </li>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ion-item ion-item {
        --padding-start: 0;
      }
      ion-label {
        max-width: none !important;
        width: 100%;
      }
    `,
  ],
})
export class CommentComponent {
  @Input() comment: IComment;
  @Input() isHighlighted = false;
  @Input() isOwner = false;
  @Input() showMenu = true;
  @Input() hasMore = true;
  @Output() commentClick = new EventEmitter<void>();
  @Output() edit = new EventEmitter<string>();
  @Output() editComment = new EventEmitter<IComment>();
  @Output() deleteComment = new EventEmitter<IComment>();
  @Output() reportComment = new EventEmitter<{
    commentId: number;
    reasonId: number;
  }>();
  @Output() blockUser = new EventEmitter<{
    commentId: number;
    user: IUserShort;
  }>();

  constructor(public popoverCtrl: PopoverController, private router: Router) {
    addIcons({ ellipsisVertical });
  }

  public navigateToUserProfile(user: IUserShort) {
    const [userType, id] = getUserTypeAndId(user);
    this.router.navigate(['tabs', 'social', userType, id]);
  }

  async openActions(ev: Event) {
    ev.stopPropagation();
    const popover = await this.popoverCtrl.create({
      component: CommentActionsPopover,
      event: ev,
      translucent: true,
      componentProps: {
        isOwner: this.isOwner,
      },
    });
    await popover.present();
    const { role, data } = (await popover.onWillDismiss()) as CommonMenuAction;
    switch (role) {
      case 'edit':
        this.editComment.emit(this.comment);
        break;
      case 'delete':
        this.deleteComment.emit(this.comment);
        break;
      case 'report':
        this.reportComment.emit({
          commentId: this.comment.Id,
          reasonId: data.reasonId,
        });
        break;
      case 'block':
        this.blockUser.emit({
          commentId: this.comment.Id,
          user: this.comment.User,
        });
        break;
    }
  }
}
