import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';
import { CommentFormComponent } from '@shared/components/comment/comment.form';
import { IComment } from '@shared/models/comment-model';
import { addIcons } from 'ionicons';
import { closeOutline, send } from 'ionicons/icons';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CommentFormComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonFooter,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Edit</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="flex flex-col-reverse h-full p-1">
        <comment-form
          #commentFormEl
          [text]="comment.Text"
          [showSubmitButton]="false"
          (edit)="update($event)"
        ></comment-form>
      </div>
    </ion-content>
    <ion-footer>
      <div class="flex flex-row-reverse">
        <ion-button
          (click)="commentFormEl.comment()"
          [disabled]="!commentFormEl.modalForm.valid"
          type="submit"
        >
          <ion-icon slot="icon-only" name="send"></ion-icon>
        </ion-button>
      </div>
    </ion-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host ion-footer ::ng-deep span {
        pointer-events: none;
      }
    `,
  ],
})
export class SocialCommentEditModal implements OnInit, OnDestroy {
  @Input() comment: IComment;
  @ViewChild('commentElement') commentElement: IonTextarea;
  @ViewChild('commentFormEl')
  commentFormEl: CommentFormComponent;
  routerSub: Subscription;

  constructor(private modalService: ModalService, private router: Router) {
    addIcons({ closeOutline, send });
  }

  ngOnInit() {
    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => this.dismiss());
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  ionViewDidEnter(): void {
    this.commentFormEl.focus();
  }

  update(text: string) {
    this.modalService.dismiss(
      {
        text,
      },
      'update',
      'edit-comment-modal'
    );
  }

  dismiss() {
    this.modalService.dismiss({}, 'cancel', 'edit-comment-modal');
  }
}
