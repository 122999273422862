import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonList,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { IState } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { CommentFormComponent } from '@shared/components/comment/comment.form';
import { CommentModule } from '@shared/components/comment/component.module';
import { IComment } from '@shared/models/comment-model';
import * as socialActions from '@store/actions/social';
import * as socialSelectors from '@store/selectors/social.selectors';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, first, map } from 'rxjs/operators';

import { SocialPostModel } from '../shared/models/social-post.model';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CommentModule,
    RxLet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonFooter,
    IonProgressBar,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title
          >{{ post?.Comments }}
          {{ post?.Comments > 1 ? 'comments' : 'comment' }}</ion-title
        >
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isPageLoading$ | async"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen #scrollElement>
      <ng-container *rxLet="areCommentsLoading$; let isLoading">
        <ul class="p-0 pt-2 m-0 list-none" *ngIf="!isLoading">
          <social-post-comment-container
            *ngFor="
              let comment of (post$ | async).TopComments;
              let i = index;
              let last = last
            "
            [comment]="comment"
            [hasMore]="last ? post.Comments > i + 1 : true"
          ></social-post-comment-container>
        </ul>

        <ion-list lines="full" *ngIf="isLoading">
          <social-post-skeleton-comment
            *ngFor="let comment of topCommentsSkeleton"
          ></social-post-skeleton-comment>
        </ion-list>
      </ng-container>
    </ion-content>

    <ion-footer>
      <ion-toolbar class="p-1">
        <comment-form
          #commentFormEl
          [isLoading]="isCommentLoading$ | async"
          (edit)="comment($event)"
        ></comment-form>
      </ion-toolbar>
    </ion-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      ion-textarea {
        width: 100%;
      }
    `,
  ],
})
export class SocialCommentModal implements OnInit, OnDestroy {
  @Input() post: SocialPostModel;
  @Input() focus = false;
  // isLoading = false;
  isCommenting = false;
  topComments: IComment[] | null = null;
  topCommentsSkeleton: null[] = [];
  @ViewChild('commentFormEl')
  commentFormEl: CommentFormComponent;
  routerSub: Subscription;

  post$: Observable<SocialPostModel>;
  areCommentsLoading$: Observable<boolean>;
  isCommentLoading$: Observable<boolean>;
  isPageLoading$: Observable<boolean>;

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<IState>,
    private readonly actions$: Actions
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.store.dispatch(
      socialActions.SocialPostLoadCommentsRequest({
        postId: this.post.Id,
      })
    );
    this.post$ = this.store.pipe(select(socialSelectors.getPost(this.post.Id)));
    this.areCommentsLoading$ = this.store.pipe(
      select(socialSelectors.areCommentsLoading(this.post.Id))
    );
    this.isCommentLoading$ = this.store.pipe(
      select(socialSelectors.isCommentLoading(this.post.Id))
    );
    this.isPageLoading$ = combineLatest([
      this.isCommentLoading$,
      this.areCommentsLoading$,
    ]).pipe(map(([loading1, loading2]) => loading1 || loading2));

    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.dismiss();
      });
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  ionViewDidEnter(): void {
    // this.loadComments();
    this.focus && this.commentFormEl.focus();
  }

  // loadComments() {
  //   this.isLoading = true;
  //   this.topCommentsSkeleton = Array(this.post?.Comments || 0).fill(null);
  //   this.cdr.markForCheck();
  //   this.socialService
  //     .getComments(this.post.Id)
  //     .pipe(
  //       tap({
  //         next: (comments) => (this.topComments = comments),
  //       }),
  //       finalize(() => {
  //         this.isLoading = false;
  //         this.cdr.markForCheck();
  //       })
  //     )
  //     .subscribe();
  // }

  comment(text: string) {
    this.isCommenting = true;
    this.cdr.markForCheck();
    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostAddCommentSuccess,
          socialActions.SocialPostAddCommentError
        ),
        first(),
        finalize(() => {
          this.isCommenting = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(({ type }) => {
        if (type === socialActions.SocialPostAddCommentSuccess.type) {
          this.dismiss();
        }
      });
    this.store.dispatch(
      socialActions.SocialPostAddCommentRequest({
        postId: this.post.Id,
        text,
      })
    );
  }

  dismiss() {
    this.modalService.dismiss(
      {
        dismissed: true,
      },
      undefined,
      'comment-modal'
    );
  }
}
