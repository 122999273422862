import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  PopoverController,
} from '@ionic/angular/standalone';
import { SocialService } from '@services/social.service';

export type Roles = 'edit' | 'delete' | 'report' | 'block' | 'backdrop';

export type CommonMenuAction =
  | {
      role: 'backdrop' | 'block' | 'edit' | 'delete';
      data: undefined;
    }
  | {
      role: 'report';
      data: { reasonId: number };
    };

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonList,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
    IonItem,
  ],
  template: `
    <ion-list lines="full">
      <ion-item-group *ngIf="!isOwner">
        <ion-item-divider>
          <ion-label>Report Comment</ion-label>
        </ion-item-divider>
        <ion-item
          (click)="action('report', { reasonId: reason.Id })"
          *ngFor="let reason of reasons$ | async"
        >
          <ion-label>{{ reason.Title }}</ion-label>
        </ion-item>

        <ion-item-divider>
          <ion-label> Block </ion-label>
        </ion-item-divider>
        <ion-item lines="none" (click)="action('block')">Block User</ion-item>
      </ion-item-group>
      <ion-item-group *ngIf="isOwner">
        <ion-item (click)="action('edit')">Edit</ion-item>
        <ion-item lines="none" (click)="action('delete')">Delete</ion-item>
      </ion-item-group>
    </ion-list>
  `,
})
export class CommentActionsPopover {
  @Input() isOwner = false;
  reasons$ = this.socialService.reportReasons();
  constructor(
    private popoverCtrl: PopoverController,
    private socialService: SocialService
  ) {}

  action(role: Roles, data?: any) {
    this.popoverCtrl.dismiss(data, role);
  }
}
