import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProductPricesModel } from '@shared/models/product-prices.model';
import { PriceRangeStandalonePipe } from '@shared/pipes/price-range2.pipe';
import { IonIcon } from "@ionic/angular/standalone";

@Component({
    selector: 'cigar-price',
    standalone: true,
    imports: [CommonModule, PriceRangeStandalonePipe, IonIcon],
    template: `
    <div class="price-type">{{ type }}</div>
    <div class="per-cigar-wrapper">
      <ng-container
        *ngIf="cigarPrices?.SinglePriceMin || cigarPrices?.SinglePrice"
      >
        <ion-icon class="cigar" src="assets/images/cigar.svg"></ion-icon>
        <div class="per-cigar">
          {{
            cigarPrices?.SinglePrice
              ? (cigarPrices?.SinglePrice | currency)
              : ([cigarPrices?.SinglePriceMin, cigarPrices?.SinglePriceMax]
                | priceRange)
          }}
        </div>
      </ng-container>
    </div>
    <div
      class="per-humidor-wrapper"
      *ngIf="cigarPrices?.BoxPriceMin || cigarPrices?.BoxPriceMax"
    >
      <!-- <div class="per-humidor-icon"></div> -->
      <ion-icon src="assets/images/my-humidors.svg"></ion-icon>
      <div class="per-humidor">
        {{
          [
            cigarPrices?.BoxPriceMin,
            cigarPrices?.BoxPriceMax,
            boxQuantityMin,
            boxQuantityMax
          ] | priceRange
        }}
      </div>
    </div>
  `,
    styleUrls: ['cigar-price.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CigarPriceComponent {
    @Input() type: string;
    @Input() cigarPrices: ProductPricesModel;
    @Input() boxQuantityMin?: number;
    @Input() boxQuantityMax?: number;
}
