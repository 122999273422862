import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'social-post-skeleton-comment',
  standalone: true,
  imports: [
    CommonModule,
    IonItem,
    IonLabel,
    IonAvatar,
    IonSkeletonText,
    IonText,
  ],
  template: `
    <ion-item>
      <ion-label class="ion-text-wrap">
        <ion-item lines="none">
          <ion-avatar slot="start">
            <ion-skeleton-text></ion-skeleton-text>
          </ion-avatar>
          <ion-label class="ion-text-wrap">
            <ion-text style="font-weight: bold;">
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 50%"
                ></ion-skeleton-text>
              </h3>
            </ion-text>
            <ion-text color="medium">
              <h4>
                <ion-skeleton-text
                  animated
                  style="width: 30%"
                ></ion-skeleton-text>
              </h4>
            </ion-text>
          </ion-label>
        </ion-item>
        <ion-text>
          <p>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          </p>
        </ion-text>
      </ion-label>
    </ion-item>
  `,
  styles: [
    `
      ion-item ion-item {
        --padding-start: 0;
      }
    `,
  ],
})
export class CommentSkeletonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
