<div class="space-y-2 pb-[2px]">
  <ion-item
    mode="ios"
    class="px-3"
    lines="none"
    style="
      --padding-start: 0;
      --inner-padding-end: 0;
      --background: transparent;
      --min-height: 75px;
    "
  >
    <ion-avatar
      class="w-16 h-16 m-0"
      slot="start"
      (click)="navigateToUserProfile(post.User)"
    >
      <img
        loading="lazy"
        *ngIf="post.User.AvatarUrl; else defaultAvatar"
        [src]="post.User.AvatarUrl | createSource"
        src-fallback="assets/images/user-image.png"
      />
      <ng-template #defaultAvatar>
        <img loading="lazy" src="assets/images/user-image.png" />
      </ng-template>
    </ion-avatar>
    <ion-label class="mx-2 ion-text-wrap">
      <h3>
        <strong>{{ post.User | userName }} </strong>
        <span class="font-medium" *ngIf="post.Action">{{
          post.Action | actionText
        }}</span>
        <strong *ngIf="post.CigarName">
          {{ ' ' + post.CigarName }}
        </strong>
      </h3>
      <a
        [routerLink]="routes.socialPost(post.Id)"
        class="m-0 mt-0.5 !text-xs !text-gray-500 dark:!text-gray-300 !font-medium"
      >
        {{ post.CreatedOn | date : 'MMM d, y, HH:mm a' }}
      </a>
      <div
        *ngIf="post.Location"
        class="w-full inline-flex items-center gap-x-1.5 rounded-full px-1 text-xs font-medium text-red-500"
      >
        <svg
          class="h-1.5 w-1.5 fill-red-500"
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx="3" cy="3" r="3" />
        </svg>
        <div class="truncate">
          {{ post.Location }}
        </div>
      </div>
    </ion-label>
    <div class="m-0" slot="end">
      <ion-button
        *ngIf="isOwner"
        (click)="presentOwnerPopover($event, post)"
        fill="clear"
        color="dark"
        size="small"
      >
        <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="!isOwner"
        (click)="reportPopover($event)"
        fill="clear"
        color="dark"
        size="small"
      >
        <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </div>
  </ion-item>
  <cigar-review
    *ngIf="post.ReviewInfo?.Rating"
    class="px-3 my-2 text-sm"
    [review]="post.ReviewInfo"
  ></cigar-review>
  <p class="mt-0 px-3 text-sm !font-medium" *ngIf="post.Text">
    <cs-text-expand [text]="post.Text" [maxLength]="800"></cs-text-expand>
  </p>
  <div class="max-w-md px-3 m-auto" *ngIf="post.ImageUrl">
    <div
      class="relative w-full overflow-hidden bg-white rounded-lg min-h-80 aspect-square group-hover:opacity-75"
    >
      <img
        loading="lazy"
        [src]="post.ImageUrl | createSource"
        alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
        class="object-cover object-center w-full h-full"
        (click)="openImageModal(post.ImageUrl)"
      />
    </div>
  </div>
  <div
    class="p-2 mx-2 rounded-lg bg-neutral-50 dark:bg-neutral-800"
    *ngIf="!hideCigar && post.CigarRating && post.CigarName"
  >
    <cigar-info
      [cigar]="post"
      [name]="post.CigarName"
      [price]="post.CigarPrices"
      [rating]="post.CigarRating"
      [prices]="post.CigarPrices"
      (cigarClicked)="navigateToProductClicked.emit(post)"
      (addToListsClicked)="addToList($event)"
      (addToHumidorsClicked)="addToHumidors()"
    >
    </cigar-info>
  </div>
  <div
    class="divide-x-0 divide-y divide-gray-300 dark:divide-gray-700 divide-solid"
  >
    <div
      class="flex justify-between px-3 align-middle"
      *ngIf="post.Likes > 0 || post.Comments > 0"
    >
      <ion-button
        fill="clear"
        expand="full"
        color="medium"
        size="small"
        (click)="openLikesModal(post)"
        *ngIf="post.Likes > 0"
      >
        {{ post.Likes }}
        <ion-icon slot="end" name="thumbs-up"></ion-icon>
      </ion-button>
      <div style="flex: 1"></div>
      <ion-button
        fill="clear"
        expand="full"
        color="medium"
        size="small"
        *ngIf="!hideCommentsModal && post.Comments > 0"
        (click)="openCommentModal(post)"
      >
        <ion-icon slot="start" name="chatbubbles-outline"></ion-icon>
        {{ post.Comments }}
      </ion-button>
    </div>
    <div
      class="flex justify-between px-1 py-[2px] align-middle overflow-x-auto"
    >
      <ion-button
        fill="clear"
        expand="full"
        [color]="post.Liked ? 'primary' : 'medium'"
        [disabled]="isLikeLoading"
        (click)="likeToggled.emit(post)"
        style="flex: 0"
        size="small"
      >
        <ion-icon
          slot="start"
          [name]="post.Liked ? 'thumbs-up' : 'thumbs-up-outline'"
        ></ion-icon>
        LIKE
      </ion-button>
      <ion-button
        fill="clear"
        expand="full"
        color="medium"
        size="small"
        *ngIf="!hideCommentsModal"
        [disabled]="isCommentLoading"
        (click)="openCommentModal(post, true)"
      >
        <ion-icon slot="start" name="chatbubble-outline"></ion-icon>
        COMMENT
      </ion-button>
      <ion-button
        *ngIf="!isOwner"
        fill="clear"
        expand="full"
        [color]="post.User.Followed ? 'primary' : 'medium'"
        [disabled]="isFollowLoading"
        (click)="toggleFollowUser(post)"
        size="small"
      >
        <ion-icon
          slot="start"
          [name]="
            post.User.Followed ? 'person-circle' : 'person-circle-outline'
          "
        ></ion-icon>
        FOLLOW
      </ion-button>
      <ion-button
        *ngIf="shareService.canShare"
        fill="clear"
        expand="full"
        color="medium"
        (click)="shareService.sharePost(post)"
        size="small"
      >
        <ion-icon slot="start" name="share-social-outline"></ion-icon>
        SHARE
      </ion-button>
    </div>
    <ion-progress-bar type="indeterminate" *ngIf="areCommentsLoading">
    </ion-progress-bar>
    <ul
      *ngIf="!areCommentsLoading && post.Comments"
      class="p-0 m-0 overflow-hidden list-none"
    >
      <social-post-comment-container
        *ngFor="
          let comment of post.TopComments | slice : 0 : maxCommentLength;
          let i = index;
          let last = last
        "
        [comment]="comment"
        [isHighlighted]="highlightedComment === comment.Id"
        [showMenu]="hideCommentsModal"
        [hasMore]="last ? post.Comments > i + 1 : true"
        (commentClick)="!hideCommentsModal && openCommentModal(post)"
      ></social-post-comment-container>
    </ul>
  </div>
</div>
<ng-content></ng-content>
