import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonProgressBar,
  IonSkeletonText,
  IonText,
  IonToolbar,
  Platform,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SocialService } from '@services/social.service';
import { UserProfileModel } from '@shared/models/user-profile.model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { ReplaceHashesWithSpansPipe } from '@shared/pipes/replace-hashes-with-spans.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import DOMPurify from 'dompurify';
import { addIcons } from 'ionicons';
import { send } from 'ionicons/icons';
import { fromEvent, interval, Observable, of } from 'rxjs';
import {
  debounce,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'comment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UserNamePipe,
    CreateSourceStandalonePipe,
    ReplaceHashesWithSpansPipe,
    IonItem,
    IonLabel,
    IonAvatar,
    IonText,
    IonButton,
    IonIcon,
    IonToolbar,
    IonList,
    IonProgressBar,
    IonSkeletonText,
    IonItemGroup,
    IonItemDivider,
  ],
  template: `
    <ion-toolbar class="suggestions" *ngIf="openTagWindow">
      <ion-list lines="full" class="ion-no-padding">
        <ion-item
          *ngFor="let user of searchedUsers; trackBy: trackByUserId"
          button
          (click)="tagUser(user)"
        >
          <ion-avatar slot="start">
            <img
              [src]="user.AvatarUrl | createSource"
              src-fallback="assets/images/user-image.png"
            />
          </ion-avatar>
          <ion-label>
            {{ user | userName }}
          </ion-label>
        </ion-item>
        <ion-item *ngIf="searchedUsers?.length === 0">
          <ion-label> Cannot find this user </ion-label>
        </ion-item>
      </ion-list>
      <ion-progress-bar
        *ngIf="isMentionLoading"
        type="indeterminate"
      ></ion-progress-bar>
    </ion-toolbar>
    <form [formGroup]="modalForm" (submit)="comment()">
      <div
        class="custom-input"
        #customInput
        contenteditable="true"
        (keydown)="keydown($event)"
        (keyup)="keyup($event)"
        [innerHtml]="text | replaceHashesWithSpans"
        [attr.data-placeholder]="placeholder"
      ></div>
      <!-- <ion-textarea
        #commentElement
        [autoGrow]="true"
        [value]="modalForm.get('text').value | replaceHashesWithSpans"
        formControlName="text"
        rows="6"
        [mention]="mentionItems"
        placeholder="Write a comment"
      ></ion-textarea> -->
      <div *ngIf="showSubmitButton" class="flex flex-row-reverse">
        <ion-button [disabled]="isLoading || !modalForm.valid" type="submit">
          <ion-icon slot="icon-only" name="send"></ion-icon>
        </ion-button>
      </div>
    </form>
  `,
  styles: [
    `
      .custom-input {
        position: relative;
        display: inline-block;
        min-height: 50px;
        max-height: 30vh;
        padding: 3px 10px;
        border: 1px solid var(--ion-color-step-200);
        border-radius: 5px;
        font-size: 13px;
        text-align: left;
        white-space: pre-wrap;
        overflow: auto;
        -webkit-user-select: text;
        user-select: text;
        outline: none;
        width: 100%;
        background-color: var(--ion-background-color);
        &.empty:before {
          content: attr(data-placeholder);
          color: #888;
          pointer-events: none;
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
      ion-list ion-item:last-child {
        --border-width: 0;
      }
    `,
  ],
})
export class CommentFormComponent implements OnInit, OnDestroy {
  @Input() showSubmitButton = true;
  @Input() isLoading = false;
  @Input() placeholder = 'Enter your comment here...';
  @Input() text = '';
  @Output() edit = new EventEmitter<string>();
  modalForm = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
  });
  @ViewChild('customInput') customInput: ElementRef;

  isMentionLoading = false;
  showMoreMentions = false;
  caretPosition: number;
  keyUpState: string;
  keyDownState: string;
  tagUserEnabled = false;
  openTagWindow = false;
  positionOfCurrentAt: number;
  inputObservable: Observable<string>;
  searchedUsers: UserProfileModel[];

  constructor(
    private platform: Platform,
    private cdr: ChangeDetectorRef,
    public socialService: SocialService
  ) {
    addIcons({ send });
  }

  ngOnInit() {
    this.modalForm.setValue({
      text: this.text,
    });
  }

  ngOnDestroy() {
    this.customInput.nativeElement.removeEventListener(
      'paste',
      this.getTextFromPastEvent
    );
  }

  ngAfterViewInit(): void {
    this.customInput.nativeElement.addEventListener(
      'paste',
      this.getTextFromPastEvent
    );
    this.inputObservable = fromEvent(this.customInput.nativeElement, 'input');
    this.inputObservable
      .pipe(
        untilDestroyed(this),
        debounce(() => interval(500)),
        switchMap(() => {
          this.caretPosition = this.getCaretPosition();
          if (!this.tagUserEnabled) {
            return of([]);
          }
          this.openTagWindow = false;
          // if (!this.checkIfTaggingInProcess()) {
          //   console.log('2', this.checkIfTaggingInProcess());
          //   return of([]);
          // }
          const spanString = this.customInput.nativeElement.innerHTML.slice(
            0,
            this.caretPosition
          );
          const searchedValue = spanString.slice(
            spanString.lastIndexOf('@') + 1,
            spanString.length
          );

          if (!searchedValue) {
            this.searchedUsers = [];
            this.showMoreMentions = false;
            return of([]);
          }

          // return of(searchedValue);
          this.openTagWindow = true;
          this.isMentionLoading = true;
          // this.searchedUsers = [];
          this.cdr.markForCheck();
          return this.userSearch(searchedValue);
        })
      )
      .subscribe((users: UserProfileModel[]) => {
        this.searchedUsers = users;
        this.cdr.markForCheck();
      });

    this.modalForm.valueChanges
      .pipe(
        startWith(this.modalForm.value),
        untilDestroyed(this),
        tap((value) => {
          const hasText = value.text.trim().length > 0;
          if (hasText) {
            this.customInput.nativeElement.classList.remove('empty');
          } else {
            this.customInput.nativeElement.classList.add('empty');
          }
        })
      )
      .subscribe();
  }

  getTextFromPastEvent = (event) => {
    let paste = event.clipboardData.getData('text');
    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    event.preventDefault();
  };

  trackByUserId(index: number, user: UserProfileModel) {
    return user.UUID;
  }

  public clearForm() {
    this.modalForm.setValue({
      text: '',
    });
    this.customInput.nativeElement.innerHTML = '';
    this.cdr.markForCheck();
  }

  checkIfTaggingInProcess() {
    if (this.positionOfCurrentAt >= 0) {
      if (
        this.customInput.nativeElement.innerHTML.charAt(
          this.positionOfCurrentAt
        ) !== '@'
      ) {
        this.openTagWindow = false;
        this.tagUserEnabled = false;
        this.positionOfCurrentAt = -1;
        return false;
      } else {
        return true;
      }
    } else {
      this.positionOfCurrentAt = this.caretPosition - 1;
      return true;
    }
  }

  userSearch(searchedValue: string) {
    return this.socialService.searchUsers(searchedValue, 0, 5).pipe(
      map((res) => res.body),
      finalize(() => (this.isMentionLoading = false))
    );
  }

  tagUser(user) {
    this.openTagWindow = false;
    this.tagUserEnabled = false;
    this.positionOfCurrentAt = -1;
    this.searchedUsers = [];

    const fromstartToCaret = this.customInput.nativeElement.innerHTML.slice(
      0,
      this.caretPosition
    );
    const indexOfSearchTerm = fromstartToCaret.lastIndexOf('@');

    const firstPart = this.customInput.nativeElement.innerHTML.slice(
      0,
      indexOfSearchTerm
    );
    const secondPart = this.customInput.nativeElement.innerHTML.slice(
      this.caretPosition,
      this.customInput.nativeElement.innerHTML.length
    );

    const name = user.Nickname
      ? `@${user.Nickname}`
      : `@${user.FirstName} ${user.LastName}`;

    this.customInput.nativeElement.innerHTML = `${firstPart}<span userid="${user.UserId}">${name}</span> ${secondPart}`;
    this.keyDownState = this.customInput.nativeElement.innerHTML;
    this.keyUpState = this.customInput.nativeElement.innerHTML;

    const stringForCaretPositioning = `${firstPart}<span userid="${user.UserId}">${name}</span>`;
    const strippedText = this.replaceSpansWithNames(stringForCaretPositioning);
    const indexOfStrippedString = strippedText.lastIndexOf(name);

    this.setCaretPosition(indexOfStrippedString + name.length + 1);
  }

  keyup(event) {
    this.modalForm.setValue({
      text: this.getSanitizedComment(),
    });
    if (this.platform.is('android')) {
      this.keyUpState = this.customInput.nativeElement.innerHTML;
      this.caretPosition = this.getCaretPosition();
      if (
        this.keyUpState.length < this.keyDownState.length &&
        this.keyDownState.lastIndexOf('</span>') ===
          this.keyDownState.length - 7
      ) {
        this.onBackSpace(event);
      }

      setTimeout(() => {
        const fromStartToCaret = this.customInput.nativeElement.innerHTML.slice(
          0,
          this.caretPosition
        );
        if (fromStartToCaret[fromStartToCaret.length - 1] === '@') {
          this.tagUserEnabled = true;
        }
      });
    }
  }

  keydown(event) {
    this.keyDownState = this.customInput.nativeElement.innerHTML;
    if (event.key === '@') {
      this.tagUserEnabled = true;
    } else if (event.keyCode === 8) {
      this.onBackSpace(event);
    } else if (event.keyCode === 46) {
      this.onDelete(event);
    } else if (event.keyCode === 13) {
      // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
      // prevent the default behaviour of return key pressed
      document.execCommand('insertHTML', false, '<br><br>');
      return false;
    }
  }

  onBackSpace(event) {
    this.caretPosition = this.getCaretPosition();
    const spanString = this.customInput.nativeElement.innerHTML.slice(
      0,
      this.caretPosition
    );

    const remainingString = this.customInput.nativeElement.innerHTML.slice(
      this.caretPosition,
      this.customInput.nativeElement.innerHTML.length
    );
    if (
      spanString.lastIndexOf('</span>') > -1 &&
      spanString.lastIndexOf('</span>') === spanString.length - 7
    ) {
      event.preventDefault();
      this.openTagWindow = false;
      this.tagUserEnabled = false;

      const firstNameIndex = spanString.lastIndexOf('">');
      const secondNameIndex = spanString.lastIndexOf('</span>');
      const name = spanString.slice(firstNameIndex + 2, secondNameIndex);

      const spanStringLastIndex = spanString.lastIndexOf('<span userid=');
      const newString = spanString.slice(0, spanStringLastIndex);
      this.customInput.nativeElement.innerHTML = newString + remainingString;

      const string = this.replaceSpansWithNames(spanString);
      const indexOfStrippedString = string.indexOf(name);
      this.setCaretPosition(indexOfStrippedString);
    }
  }

  onDelete(event) {
    this.caretPosition = this.getCaretPosition();
    const spanString = this.customInput.nativeElement.innerHTML.slice(
      this.caretPosition,
      this.customInput.nativeElement.innerHTML.length
    );

    const remainingString = this.customInput.nativeElement.innerHTML.slice(
      0,
      this.caretPosition
    );

    if (spanString.indexOf('<span ') === 0) {
      event.preventDefault();
      this.openTagWindow = false;
      this.tagUserEnabled = false;

      const secondSpanIndex = spanString.indexOf('</span>');
      const newString = spanString.slice(
        secondSpanIndex + 7,
        spanString.length
      );

      this.customInput.nativeElement.innerHTML = remainingString + newString;

      const string = this.replaceSpansWithNames(remainingString);

      this.setCaretPosition(string.length);
    }
  }

  getCaretPosition() {
    const node = this.customInput.nativeElement;
    const range = window.getSelection().getRangeAt(0),
      preCaretRange = range.cloneRange(),
      tmp = document.createElement('div');
    let caretPosition;

    preCaretRange.selectNodeContents(node);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tmp.appendChild(preCaretRange.cloneContents());
    caretPosition = tmp.innerHTML.length;
    return caretPosition;
  }

  setCaretPosition(chars) {
    if (chars >= 0) {
      const selection = window.getSelection();
      const range = this.createRange(
        this.customInput.nativeElement,
        { count: chars },
        null
      );

      if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }

  createRange(node, chars, range) {
    if (!range) {
      range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (let lp = 0; lp < node.childNodes.length; lp++) {
          range = this.createRange(node.childNodes[lp], chars, range);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }
    return range;
  }

  replaceSpansWithNames(string) {
    const firstSpanIndex = string.indexOf('<span ');
    if (firstSpanIndex >= 0) {
      const secondSpanIndex = string.indexOf('</span>') + 7;
      const span = string.slice(firstSpanIndex, secondSpanIndex);

      const firstNameIndex = span.indexOf('">');
      const secondNameIndex = span.indexOf('</span>');
      const name = span.slice(firstNameIndex + 2, secondNameIndex);
      const newString = string.replace(span, name);
      return this.replaceSpansWithNames(newString);
    } else {
      return string;
    }
  }

  replaceSpanElementsWithHashTags(string) {
    const firstIndex = string.indexOf('<span ');
    if (firstIndex >= 0) {
      const secondIndex = string.indexOf('</span>') + 7;
      const span = string.slice(firstIndex, secondIndex);

      const firstQuotes = span.indexOf('userid="') + 8;
      const secondQoutes = span.lastIndexOf('">');

      const userid = span.slice(firstQuotes, secondQoutes);
      const newString = string.replace(span, `##${userid}##`);
      return this.replaceSpanElementsWithHashTags(newString);
    } else {
      return string;
    }
  }

  focus() {
    setTimeout(() => this.customInput.nativeElement.focus(), 0);
  }

  public comment() {
    this.edit.emit(this.getSanitizedComment());
    if (this.platform.is('capacitor')) {
      Keyboard.hide();
    }
  }

  getSanitizedComment(): string {
    const newComment = this.replaceSpanElementsWithHashTags(
      decodeHtml(this.customInput.nativeElement.innerHTML)
    );
    return DOMPurify.sanitize(newComment, {
      ALLOWED_TAGS: ['span'],
      ALLOWED_ATTR: ['userid'],
    });
  }
}

function decodeHtml(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}
