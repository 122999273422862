import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { SocialPostModel } from '@shared/models/social-post.model';
import * as socialActions from '@store/actions/social';
import * as socialSelectors from '@store/selectors/social.selectors';

import { SocialPostComponent } from './social-post.component';
import { UserType } from '@shared/models/user.model';

@Component({
  selector: 'cs-social-post-container',
  standalone: true,
  imports: [CommonModule, SocialPostComponent],
  template: `
    <cs-social-post
      [post]="post"
      [hideCigar]="hideCigar"
      [isLikeLoading]="isLikeLoading$ | async"
      [isCommentLoading]="isCommentLoading$ | async"
      [isFollowLoading]="isFollowLoading$ | async"
      [maxCommentLength]="maxCommentLength"
      [areCommentsLoading]="areCommentsLoading"
      [hideCommentsModal]="hideCommentsModal"
      (navigateToProductClicked)="navigateToProductClicked.emit($event)"
      (reportPost)="reportPost($event)"
      (blockUser)="blockUser($event)"
      (likeToggled)="likeToggled($event)"
      (followToggle)="followToggle($event)"
      (deletePost)="deletePost($event)"
    >
      <ng-content></ng-content>
    </cs-social-post>
  `,
  styles: [
    `
      :host {
        display: block;
        @apply border-0 border-b-[10px] border-solid border-b-gray-200 dark:border-b-gray-800;
      }
    `,
  ],
})
export class SocialPostContainer implements OnInit {
  @Input() post: SocialPostModel;
  @Input() hideCigar = false;
  @Input() maxCommentLength = Infinity;
  @Input() areCommentsLoading = false;
  @Input() hideCommentsModal = false;
  @Output() navigateToProductClicked = new EventEmitter<SocialPostModel>();

  isLikeLoading$ = this.store.select((state) =>
    socialSelectors.isLikeLoading(this.post.Id)(state)
  );

  isCommentLoading$ = this.store.select((state) =>
    socialSelectors.isCommentLoading(this.post.Id)(state)
  );

  isFollowLoading$ = this.store.select((state) =>
    socialSelectors.isFollowLoading(this.post.User)(state)
  );

  constructor(private store: Store<IState>) {}

  ngOnInit() {}

  deletePost({ postId }: { postId: number }) {
    this.store.dispatch(
      socialActions.SocialPostRemoveRequest({
        postId,
      })
    );
  }

  likeToggled(post: SocialPostModel) {
    this.store.dispatch(
      socialActions.SocialPostLikeToggleRequest({
        postId: post.Id,
        isLiked: post.Liked === true,
      })
    );
  }

  reportPost({ postId, reasonId }: { postId: number; reasonId: number }) {
    this.store.dispatch(
      socialActions.SocialPostReportRequest({
        postId,
        reasonId,
      })
    );
  }

  blockUser({ userType, userId }: { userType: UserType; userId: string }) {
    this.store.dispatch(
      socialActions.SocialBlockUserRequest({
        userType,
        userId,
      })
    );
  }

  followToggle({
    userType,
    userId,
    isFollowed,
  }: {
    userType: UserType;
    userId: string;
    isFollowed: boolean;
  }) {
    this.store.dispatch(
      socialActions.SocialPostFollowToggleRequest({
        userType,
        userId,
        isFollowed,
      })
    );
  }
}
