import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommentComponent } from './comment.component';
import { CommentFormComponent } from './comment.form';
import { CommentSkeletonComponent } from './comment.skeleton.component';
import { CommentActionsPopover } from './comments-actions.popover';
import { CommentContainer } from './comment.container';

@NgModule({
  imports: [
    CommonModule,
    CommentComponent,
    CommentContainer,
    CommentFormComponent,
    CommentActionsPopover,
    CommentSkeletonComponent,
  ],
  exports: [
    CommentSkeletonComponent,
    CommentContainer,
    CommentComponent,
    CommentFormComponent,
  ],
  declarations: [],
  providers: [],
})
export class CommentModule {}
