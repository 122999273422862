import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular/standalone';
import { SocialCommentEditModal } from '@modals/social-comment-edit-modal.component';
import { IState } from '@models';
import { IUserShort } from '@models/user';
import { Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { IComment } from '@shared/models/comment-model';
import { ActiveUserService } from '@shared/services/active-user.service';
import * as socialActions from '@store/actions/social';
import { getUserTypeAndId } from '@utils/users';

import { CommentComponent } from './comment.component';

@Component({
  selector: 'social-post-comment-container',
  standalone: true,
  imports: [CommonModule, CommentComponent],
  template: `
    <social-post-comment
      [comment]="comment"
      [isHighlighted]="isHighlighted"
      [isOwner]="isOwner"
      (editComment)="openEditCommentModal($event)"
      (deleteComment)="deletePrompt($event)"
      (reportComment)="reportComment($event)"
      (blockUser)="blockUser($event)"
      (commentClick)="commentClick.emit()"
      [hasMore]="hasMore"
    ></social-post-comment>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentContainer {
  isCommenting = false;
  isOwner = false;
  @Input() comment: IComment;
  @Input() isHighlighted = false;
  @Input() showMenu = true;
  @Input() hasMore = true;
  @Output() commentClick = new EventEmitter<void>();
  @Output() edit = new EventEmitter<string>();

  constructor(
    public popoverCtrl: PopoverController,
    private activeUserService: ActiveUserService,
    private store: Store<IState>,
    private modalService: ModalService,
    private alertCtrl: AlertController,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comment?.currentValue) {
      this.isOwner = this.isOwnedByUser(this.comment);
    }
  }

  isOwnedByUser(comment) {
    if (this.activeUserService.user() && comment?.User?.UserId) {
      return comment.User.UserId === this.activeUserService.user().Id;
    }
    return false;
  }
  async openEditCommentModal(comment: IComment) {
    const modal = await this.modalService.createCSModal(
      SocialCommentEditModal,
      {
        comment,
      },
      {
        common: { id: 'edit-comment-modal' },
      }
    );
    this.isCommenting = true;
    this.cdr.markForCheck();
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (role === 'update') {
      this.store.dispatch(
        socialActions.SocialPostEditCommentRequest({
          postId: comment.PostId,
          commentId: comment.Id,
          text: data.text,
        })
      );
    }
  }

  blockUser(e: { commentId: number; user: IUserShort }) {
    const [userType, userId] = getUserTypeAndId(e.user);
    this.store.dispatch(
      socialActions.SocialBlockUserRequest({
        userId,
        userType,
      })
    );
  }

  reportComment(e: { commentId: number; reasonId: number }) {
    this.store.dispatch(
      socialActions.SocialPostReportCommentRequest({
        commentId: e.commentId,
        reasonId: e.reasonId,
      })
    );
  }

  deletePrompt(comment) {
    this.alertCtrl
      .create({
        header: 'Delete comment',
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Delete',
            handler: () => {
              this.store.dispatch(
                socialActions.SocialPostRemoveCommentRequest({
                  postId: comment.PostId,
                  commentId: comment.Id,
                })
              );
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
}
