import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  PopoverController,
  NavParams,
  IonList,
  IonItem,
} from '@ionic/angular/standalone';

import { SocialPostModel } from '@shared/models/social-post.model';

export enum PostPopoverRoles {
  BACKDROP = 'backdrop',
  EDIT = 'edit',
  REMOVE = 'remove',
}
@Component({
  templateUrl: 'post-popover.html',
  standalone: true,
  imports: [IonList, CommonModule, IonList, IonItem],
})
export class PostPopoverPage {
  @Input() post: SocialPostModel;
  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {}

  edit() {
    this.popoverCtrl.dismiss({}, PostPopoverRoles.EDIT);
  }

  remove() {
    this.popoverCtrl.dismiss({}, PostPopoverRoles.REMOVE);
  }
}
