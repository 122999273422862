import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  PostPopoverPage,
  PostPopoverRoles,
} from '@components/social/post-popover/post-popover';
import { AppRoutes } from '@utils/routes';

import { ReportPopover } from '@components/social/report-popover/report-popover';
import {
  AlertController,
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  PopoverController,
} from '@ionic/angular/standalone';
import {
  CustomPost2Roles,
  CustomPostModal,
} from '@modals/social-custom-post-modal.component';
import { ImgModal } from '@modals/img-modal/img-modal.component';
import { LoginModal } from '@modals/login.modal.component';
import { SocialCommentModal } from '@modals/social-comment-modal.component';
import { SocialLikesModal } from '@modals/social-likes-modal.component';
import { ICigarLogListIds } from '@models';
import { IUserShort } from '@models/user';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { SocialService } from '@services/social.service';
import { ToastService } from '@services/toast.service';
import { CigarInfoComponent } from '@shared/components/cigar-info/cigar-info.component';
import { CommentModule } from '@shared/components/comment/component.module';
import { SocialPostModel } from '@shared/models/social-post.model';
import { UserType } from '@shared/models/user.model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { ActiveUserService } from '@shared/services/active-user.service';
import { CigarListActionsService } from '@shared/services/cigar-list-actions.service';
import { DeviceService } from '@shared/services/device.service';
import { LoaderService } from '@shared/services/loader.service';
import { getUserTypeAndId } from '@utils/users';
import { addIcons } from 'ionicons';
import {
  chatbubbleOutline,
  chatbubblesOutline,
  ellipsisVertical,
  personCircle,
  personCircleOutline,
  thumbsUp,
  thumbsUpOutline,
  shareSocialOutline,
} from 'ionicons/icons';
import { finalize } from 'rxjs/operators';

import { CigarBrandLogoComponent } from '../cigar-brand-logo/cigar-brand-logo.component';
import { CigarPriceComponent } from '../cigar-price/cigar-price.component';
import { CigarReviewComponent } from '../cigar-review/cigar-review.component';
import { RatingStarsComponent } from '../rating-stars/rating-stars.component';
import { actionTextSocialPipe } from './action-text.pipe';
import { TextExpandComponent } from '@components/text-expand.component';
import { ShareService } from '@shared/services/share.service';

@Component({
  selector: 'cs-social-post',
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    CigarPriceComponent,
    CigarReviewComponent,
    CigarBrandLogoComponent,
    RatingStarsComponent,
    UserNamePipe,
    RouterModule,
    CommentModule,
    SocialCommentModal,
    CigarInfoComponent,
    RxLet,
    actionTextSocialPipe,
    IonIcon,
    IonLabel,
    IonItem,
    IonAvatar,
    IonButton,
    TextExpandComponent,
  ],
  templateUrl: 'social-post.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class SocialPostComponent {
  public routes = AppRoutes;
  @Input() hideCigar = false;
  @Input() isLikeLoading = false;
  @Input() isCommentLoading = false;
  @Input() areCommentsLoading = false;
  @Input() hideCommentsModal = false;
  @Input() isFollowLoading = false;
  @Input() maxCommentLength = Infinity;
  isOwner = false;
  @Input() highlightedComment?: number;
  @Input() post: SocialPostModel;
  @Output() navigateToProductClicked = new EventEmitter<SocialPostModel>();
  @Output() likeToggled = new EventEmitter<SocialPostModel>();
  @Output() followToggle = new EventEmitter<{
    userType: UserType;
    userId: string;
    isFollowed: boolean;
  }>();
  @Output() reportPost = new EventEmitter<{
    postId: number;
    reasonId: number;
  }>();
  @Output() deletePost = new EventEmitter<{ postId: number }>();
  @Output() blockUser = new EventEmitter<{
    userType: UserType;
    userId: string;
  }>();

  constructor(
    private socialService: SocialService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private router: Router,
    private loader: LoaderService,
    private deviceService: DeviceService,
    public activeUserService: ActiveUserService,
    private modalService: ModalService,
    private toastService: ToastService,
    private cigarListActions: CigarListActionsService,
    public shareService: ShareService
  ) {
    addIcons({
      thumbsUp,
      thumbsUpOutline,
      chatbubbleOutline,
      personCircle,
      personCircleOutline,
      chatbubblesOutline,
      ellipsisVertical,
      shareSocialOutline,
    });
  }

  isOwnedByUser(user) {
    if (this.activeUserService.user() && user.UserId) {
      return user.UserId === this.activeUserService.user().Id;
    } else {
      return this.deviceService.getDeviceID() === user.UUID;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post?.currentValue) {
      this.isOwner = this.isOwnedByUser(this.post.User);
    }
  }

  navigateToUserProfile(user: IUserShort) {
    const [userType, id] = getUserTypeAndId(user);
    this.router.navigate(['tabs', 'social', userType, id]);
  }

  async openLoginModal() {
    const modal = await this.modalService.createCSModal(LoginModal, {});
    return modal.present();
  }

  async toggleFollowUser(post) {
    if (!this.activeUserService.isAuthenticated()) {
      this.openLoginModal();
      return;
    }
    const [userType, userId] = getUserTypeAndId(post.User);
    this.followToggle.emit({
      userType,
      userId,
      isFollowed: post.User.Followed,
    });
  }

  async openImageModal(src: string) {
    const modal = await this.modalService.createCSModal(
      ImgModal,
      {
        src,
      },
      {
        common: {
          id: 'img-modal',
          handle: false,
          animated: false,
        },
      }
    );
    await modal.present();
  }

  async openCommentModal(post: SocialPostModel, focus = false) {
    if (!this.activeUserService.isAuthenticated()) {
      this.openLoginModal();
      return;
    }

    const modal = await this.modalService.createCSModal(
      SocialCommentModal,
      {
        post,
        focus,
      },
      {
        common: {
          id: 'comment-modal',
        },
      }
    );
    await modal.present();
  }

  async openLikesModal(post: SocialPostModel) {
    const modal = await this.modalService.createCSModal(SocialLikesModal, {
      postId: post.Id,
    });

    await modal.present();
  }

  async editPost(post: SocialPostModel) {
    const modal = await this.modalService.createCSModal(CustomPostModal, {
      post,
    });
    await modal.present();
    const { role } = await modal.onDidDismiss();

    switch (role as CustomPost2Roles) {
      case CustomPost2Roles.SUCCESS:
        await this.toastService.show('Successfully edited');
        break;
    }
  }

  async removePost(postId: number) {
    const alert = await this.alertCtrl.create({
      subHeader: 'Are you sure you want to delete this post?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          handler: () => {
            this.deletePost.emit({ postId });
          },
        },
      ],
    });
    await alert.present();
  }

  async presentOwnerPopover(event: Event, post: SocialPostModel) {
    const popover = await this.popoverCtrl.create({
      component: PostPopoverPage,
      event,
      translucent: true,
      componentProps: {
        post,
      },
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    switch (role as PostPopoverRoles) {
      case PostPopoverRoles.EDIT:
        this.editPost(post);
        break;
      case PostPopoverRoles.REMOVE:
        this.removePost(post.Id);
        break;
    }
  }

  async reportPopover(myEvent: Event) {
    await this.loader.show();
    this.socialService
      .reportReasons()
      .pipe(finalize(() => this.loader.hide()))
      .subscribe(async (res) => {
        const popover = await this.popoverCtrl.create({
          component: ReportPopover,
          componentProps: {
            showConfirm: (reason) => {
              this.alertCtrl
                .create({
                  subHeader: `Are you sure you want to flag this post${
                    reason.Id !== 4 ? ` as ${reason.Title}?` : '?'
                  }`,
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                    },
                    {
                      text: 'Yes',
                      handler: (data) => {
                        this.reportPost.emit({
                          postId: this.post.Id,
                          reasonId: reason.Id,
                        });

                        if (data[0]) {
                          const [userType, userId] = getUserTypeAndId(
                            this.post.User
                          );

                          this.blockUser.emit({
                            userType,
                            userId,
                          });
                        }
                      },
                    },
                  ],
                  inputs: [
                    {
                      name: 'blockUser',
                      type: 'checkbox',
                      label: 'Block this user',
                      value: true,
                      checked: false,
                    },
                  ],
                })
                .then((alert) => {
                  alert.present();
                });
            },
            reasons: res,
          },
          cssClass: 'delete-post-popover',
          event: myEvent,
        });

        await popover.present();
      });
  }

  addToHumidors() {
    try {
      const { ProductId, LineId, CigarName } = this.post;
      if (!ProductId && !LineId) {
        return;
      }
      this.cigarListActions.addToHumidors({
        Name: CigarName,
        ProductId,
        LineId,
      });
    } catch (error) {}
  }

  addToList(listId: ICigarLogListIds) {
    try {
      const { ProductId, LineId } = this.post;
      if (!ProductId && !LineId) {
        return;
      }
      this.cigarListActions.addToRequest(listId, {
        ProductId,
        LineId,
      });
    } catch (error) {}
  }
}
